<template>
  <div>
    <v-alert color="secondary" text outlined border="left">
      <v-row align="center">
        <h4 class="blacko--text mr-2">اضافة {{ $route.meta.single }}</h4>
        <v-spacer />
        <v-btn color="primary" @click="$router.go(-1)" outlined>
          <v-icon> navigate_next </v-icon>
          <h3>رجوع</h3>
        </v-btn>
      </v-row>
    </v-alert>

    <v-card outlined>
      <v-container fluid>
        <v-form ref="addEmail" v-model="vaild">
          <v-row>
            <v-col md="12">
              <h4 class="mb-2">{{ $t('alanwan') }} <span class="required">*</span></h4>
              <v-text-field
                placeholder="العنوان"
                outlined
                hide-details="auto"
                required
                :rules="[$global.state.required()]"
                v-model="form.title"
              />
            </v-col>
            <v-col md="12">
              <h4 class="mb-2">{{ $t('alrsalh') }} <span class="required">*</span></h4>
              <!-- <v-textarea placeholder="الرسالة" filled outlined hide-details="auto" :rules="[$global.state.required()]" required v-model="form.body" /> -->
              <VueEditor dir="ltr" 
                :rules="[$global.state.required()]"  v-model="form.body" />
            </v-col>
            <v-col md="12">
              <h4 class="mb-2">{{ $t('almstlmyn') }} <span class="required">*</span></h4>
              <v-autocomplete
                v-model="receivers"
                :items="$global.state.users"
                item-text="fullName"
                item-value="id"
                hide-details="auto"
                outlined
                :rules="[$global.state.required()]"
                multiple
                small-chips
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggle">
                    <v-list-item-action>
                      <v-icon
                        :color="
                          receivers.length > 0 ? 'indigo darken-4' : ''
                        "
                        >{{  receivers.length ==  $global.state.users.length ? 'mdi-close-box' : 'mdi-minus-box'}}</v-icon
                      >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t('thdyd-alkl') }}
                        </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col md="12">
              <h4 class="mb-2">
                {{$t('almrfqat')}} 
              </h4>
              <MultipleFiles
                :field="{
                  field: {
                    title: $t('almrfqat'),
                    type: 'multipleFiles',
                    required: false,
                  },
                  isRequired: false,
                }"
                v-model="attachments"
                :isRequired="true"
              />
            </v-col>
            <v-col cols="12">
              <div class="d-flex justify-end">
                <v-btn :disabled="!vaild" color="primary" @click="submit" large>
                  <v-icon>mdi-check</v-icon>
                  {{$t('save')}}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import MultipleFiles from "@/components/fields/MultipleFiles";
import { VueEditor } from "vue2-editor";
export default {
  components: {
    VueEditor,
    MultipleFiles,
  },
  data() {
    return {
      form: {
        title: "",
        body: "",
        isRead: false,
        isArchive: false,
        senderId: "",
        attachments: [],
        receivers: [],
      },
      vaild:false,
      attachments: "",
      receivers: [],
    };
  },
  methods: {
    toggle () {
        if (this.receivers.length == this.$global.state.users.length) {
          this.receivers = []
        } else {
          this.receivers = this.$global.state.users.map(user => user.id)
        }
    },
    async submit() {
      let attachments = this.attachments.split(",");
      this.form.attachments = attachments.map((attachment) => {
        return {
          name: attachment.split("/").pop(),
          path: attachment,
          type: "file",
        };
      });
      //   if (this.$global.state.path) {
      //     this.form.attachments.push({
      //       name: this.$global.state.path,
      //       path: this.$global.state.path,
      //       type: "file",
      //     });
      //   }
      for (let i = 0; i < this.receivers.length; i++) {
        let user = this.receivers[i];
        this.form.receivers.push({
          receiverId: user,
          isCC: false,
        });
      }
      this.form.senderId = this.$store.state.user.id;
      await this.$http.post("/Email", this.form);
      this.$router.push("/emails");
    },
  },
  created() {
    this.$global.dispatch("getUser");
  },
};
</script>

<style>
.ql-editor {
  text-align: right !important;
}
</style>